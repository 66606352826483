import React from "react"
import styled from "@emotion/styled"
import * as vars from "../styles/vars"

const Hero404 = () => {
  return (
    <Hero404Section className="hero">
      <div className="container">
        <div className="info">
          <h1>
            <span>Hey!</span> Parece que alguien anda más perdido que un pulpo
            en un garaje.
          </h1>
        </div>
      </div>
    </Hero404Section>
  )
}

export default Hero404

const Hero404Section = styled.section`
  padding-top: 200px;
  position: relative;

  .container {
    height: auto;
  }
  .info {
    text-align: left;
    width: 100%;
    h1 {
      margin-bottom: 65px;
      ${vars.sm} {
        margin-bottom: 40px;
      }
      ${vars.md} {
        margin-bottom: 40px;
      }
      ${vars.xxl} {
        margin-bottom: 70px;
      }
    }
  }
`
