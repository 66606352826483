import React from "react"
import Layout from "../components/layout"
import Hero404 from "../components/hero404"
import Description404 from "../components/description404"
import SEO from "../components/seo"
const NotFoundPage = () => {
  return (
    <Layout>
      <SEO
        title="404: Not found"
        description="La página que estás buscando no existe."
      />
      <Hero404 />
      <Description404 />
    </Layout>
  )
}

export default NotFoundPage
