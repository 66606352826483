import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import * as vars from "../styles/vars"

const Description404 = () => {
  return (
    <Description className="description">
      <div className="container">
        <section className="text">
          <p>
            ¡Bienvenido a ‘NOT FOUND 404’! Una página que te recuerda que todos
            cometemos errores y nos hemos perdido alguna vez en la vida (aunque
            sea por hacerle caso al GPS).
          </p>
        </section>
      </div>
    </Description>
  )
}

export default Description404
const Description = styled.div`
  padding-bottom: 25px;
  ${vars.sm} {
    padding-bottom: 50px;
  }
  ${vars.lg} {
    padding-bottom: 70px;
  }
  ${vars.xl} {
    padding-bottom: 50px;
  }
  ${vars.xxl} {
    padding-bottom: 150px;
  }
  p {
    font-family: ${vars.barlow};
    font-weight: 300;
    margin-bottom: 40px;
  }
`
